<template>
  <div>
    <v-card class="mb-5" outlined color="white">
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </v-card>

    <div class="mt-10" v-if="zointeg.length === 0">
      <v-alert type="error">
        Belum Ada <strong>Zona Integrasi</strong> Untuk Saat Ini
      </v-alert>
    </div>

    <div class="mt-10 pb-10" v-else>
      <v-row>
        <v-col cols="12" md="4" v-for="item in zointeg" :key="item.zointeg_id">
          <v-card outlined height="500">
            <iframe
              :src="getPDF(item.zointeg_file)"
              type="application/pdf"
              height="100%"
              width="100%"
            ></iframe>
          </v-card>
          <div class="mt-5">
            <h3>{{ item.zointeg_nama }}</h3>
          </div>
          <v-divider></v-divider>
          <div>
            <span class="subtitle-2 grey--text">
              {{ item.zointeg_ket }}
            </span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    zointeg: [],
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Program",
        disabled: true,
        href: "",
      },
      {
        text: "Zona Integrasi",
        disabled: false,
        href: "/program/zointeg",
      },
    ],
  }),

  mounted() {
    this.getData();
  },

  methods: {
    getPDF(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/zointegFile/" + value;
      } else {
        return process.env.VUE_APP_API_BASE + "upload/zointegFile/pdf-default.pdf";
      }
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "zointeg")
        .then((res) => {
          this.zointeg = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
